<template>
    <div class="page">
        <el-form size="small" class="query-form searchForm" ref="searchForm" :model="searchForm"
                 label-width="120px" @keyup.enter.native="search(1)">
            <el-form-item prop="transferDepartment" label="移交单位部门：">
                <el-select v-model="searchForm.transferDepartment" placeholder="请选择" clearable class="w100i">
                    <el-option
                            :key="item.transferDepartment"
                            :label="item.transferDepartmentName"
                            :value="item.transferDepartment"
                            v-for="item in departmentOpt">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="transferUser" label="移交人员：">
                <el-select v-model="searchForm.transferUser" filterable placeholder="请选择" clearable class="w100i">
                    <el-option v-for="item in transferUserOpt" :key="item.userId" :label="item.name"
                               :value="item.userId"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="transferTimeStart" label="移交时间：">
                <el-date-picker v-model="transferTime" value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                type="datetimerange" clearable start-placeholder="开始时间"
                                end-placeholder="结束时间" class="w100i">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="acceptUnit" label="接收单位：">
                <el-input v-model.trim="searchForm.acceptUnit" maxlength="50" placeholder="请输入接收单位（50字内）"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="acceptDepartment" label="接收单位部门：">
                <el-input v-model.trim="searchForm.acceptDepartment" maxlength="50" placeholder="请输入接收单位部门（50字内）"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="acceptUser" label="接收人员：">
                <el-input v-model.trim="searchForm.acceptUser" maxlength="20" placeholder="请输入接收人员（20字内）"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="acceptTimeStart" label="接收时间：">
                <el-date-picker v-model="acceptTime" value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                type="datetimerange" clearable start-placeholder="开始时间"
                                end-placeholder="结束时间" class="w100i">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="keyWord" label="输入搜索：">
                <el-input v-model.trim="searchForm.keyWord" maxlength="50" placeholder="请输入题名（50字内）"
                          clearable></el-input>
            </el-form-item>
            <div class="flex_b_c p_b2">
                <div>
                    <el-button type="primary" size="small" icon="el-icon-search" @click="search(1)">查询</el-button>
                    <el-button size="small" icon="el-icon-refresh-right" @click="reset()">重置</el-button>
                </div>
                <div>
                    <el-button size="small" type="primary" icon="el-icon-plus" v-if="hasPermission('reception:list:add')"
                               @click="addEdit(null, 'add')">新增移交
                    </el-button>
                    <el-button size="small" type="primary" icon="el-icon-delete" v-if="hasPermission('reception:list:delete')"
                               @click="del()">批量删除
                    </el-button>
                </div>
            </div>
        </el-form>
        <div class="bg-white">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 450px)"
                    @selection-change="selectionChangeHandle"
                    class="table" ref="multipleTable" row-key='id'>
                <el-table-column type="selection" :reserve-selection='true' fixed/>
                <el-table-column prop="transferUnitName" label="移交单位" show-overflow-tooltip></el-table-column>
                <el-table-column prop="transferDepartmentName" label="移交单位部门" show-overflow-tooltip></el-table-column>
                <el-table-column prop="transferUserName" label="移交人员" show-overflow-tooltip></el-table-column>
                <el-table-column prop="transferTime" label="移交时间" sortable show-overflow-tooltip></el-table-column>
                <el-table-column prop="acceptUnit" label="接收单位" show-overflow-tooltip></el-table-column>
                <el-table-column prop="acceptDepartment" label="接收单位部门" show-overflow-tooltip></el-table-column>
                <el-table-column prop="acceptUser" label="接收人员" show-overflow-tooltip></el-table-column>
                <el-table-column prop="acceptTime" label="接收时间" sortable show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="180" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-show="hasPermission('reception:list:view')"
                                   @click="addEdit(scope.row, 'view')">详情
                        </el-button>
                        <el-button type="text" size="small" v-show="hasPermission('reception:list:edit')"
                                   @click="addEdit(scope.row, 'edit')">修改
                        </el-button>
                        <el-button type="text" size="small" v-show="hasPermission('reception:list:delete')"
                                   @click="del(scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <ListForm ref="listForm" @refreshDataList="search()"></ListForm>
    </div>
</template>

<script>
    import ListForm from './listForm'

    export default {
        components: {
            ListForm
        },
        data() {
            return {
                searchForm: {
                    transferDepartment: '',
                    transferUser: '',
                    transferTimeStart: '',
                    transferTimeEnd: '',
                    acceptUnit: '',
                    acceptDepartment: '',
                    acceptUser: '',
                    acceptTimeStart: '',
                    acceptTimeEnd: '',
                    keyWord: '',
                    current: 1,
                    size: 10,
                },
                transferTime: [],
                acceptTime: [],
                transferUserOpt: [],
                departmentOpt: [],
                loading: false,
                dataList: [],
                total: 0,
                dataListSelections: [],
            }
        },
        mounted() {
            this.search(1)
        },
        methods: {
            // 检索
            search(type) {
                this.getDepartment()
                if (type == 1) {
                    this.searchForm.current = 1
                }
                if (type == 0) {
                    this.$refs.multipleTable.clearSelection()
                }
                this.loading = true
                let p = JSON.parse(JSON.stringify(this.searchForm))
                p.transferTimeStart = this.transferTime ? this.transferTime[0] : ''
                p.transferTimeEnd = this.transferTime ? this.transferTime[1] : ''
                p.acceptTimeStart = this.acceptTime ? this.acceptTime[0] : ''
                p.acceptTimeEnd = this.acceptTime ? this.acceptTime[1] : ''
                this.getAuditTransfer(p)
                this.$axios(this.api.record.archivesTransferList, p, 'post').then(res => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.search()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })

            },
            // 获取移交人员
            getAuditTransfer(p) {
                this.$axios(this.api.record.auditTransfer, p, 'post').then(res => {
                    if (res.status) {
                        this.transferUserOpt = res.data
                    }
                })
            },
            // 新增
            addEdit(row, method) {
                this.$refs.listForm.init(row, method)
            },
            // 删除
            del(id) {
                let ids = [id];
                if (!id && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if (!id) {
                    ids = this.dataListSelections.map(item => {
                        return item.id
                    })
                }
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.removeArchivesTransferByIds, ids, 'post').then(data => {
                        if (data.status) {
                            this.$message.success('删除成功')
                            this.search(0)
                        } else {
                            this.$message.error('删除失败')
                        }
                    })
                })
            },
            // 获取部门
            getDepartment() {
                this.$axios(this.api.record.findArchivesTransferDepartment).then(data => {
                    if (data && data.status) {
                        this.departmentOpt = data.data
                    }
                })
            },
            //重置
            reset() {
                this.$refs.multipleTable.clearSelection()
                this.$refs.searchForm.resetFields();
                this.transferTime = []
                this.acceptTime = []
                this.search(1)
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        }
    }
</script>

<style scoped lang="scss">
    .searchForm {
        .el-form-item--small.el-form-item {
            display: inline-block;
            width: 33%;
        }
    }
</style>
